import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import Video from "./video";

const Heading = styled.h4`
	font-size: var(--h5);
	@media screen and (min-width: 768px) {
		font-size: var(--h4);
	}
`;

const Date = styled.span`
	font-size: var(--small);
	@media screen and (min-width: 768px) {
		font-size: 1rem;
	}
	color: var(--accent);
`;

const Project = styled.article`
	position: relative;
	.projectLink {
		display: flex;
		align-items: center;
		.projectImage {
			max-width: 150px;
			min-width: 80px;
			width: 35%;
			margin-right: 16px;
			flex-shrink: 0;
			transition: filter 0.15s;
		}
	}
	@media screen and (min-width: 768px) {
		.projectLink {
			flex-direction: column;
			.projectImage {
				margin-bottom: 16px;
				margin-right: 0;
				max-width: none;
				min-width: 0;
				width: auto;
			}
		}
	}
	&:hover {
		/* background: var(--primary); */

		.projectImage {
			filter: saturate(1.1);
		}
		${Heading} {
			text-decoration: underline;
			/* color: var(--light); */
		}
		/* ${Date} {
			color: var(--light);
		} */
	}
`;

const ProjectText = styled.div`
	align-self: center;
	@media screen and (min-width: 768px) {
		align-self: flex-start;
	}
`;

// const ThumbnailVideo = styled.div`
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// `;

const ProjectCard = ({ project, small }) => {
	return (
		<Project className='blog-card' small={small} onMouseEnter={() => {}}>
			<Link to={`/projects/${project.slug}`} className='projectLink'>
				<GatsbyImage className='projectImage' image={project.thumbnail?.gatsbyImageData} alt={project.title} />
				<ProjectText>
					<Date small={small}>{project.inDevelopment ? 'In development' : project.date}</Date>
					<Heading small={small}>{project.title}</Heading>
				</ProjectText>
			</Link>
			{/* <ThumbnailVideo>
				{project.video ? <Video video={project.video}></Video> : null}
			</ThumbnailVideo> */}
		</Project>
	);
};

export default ProjectCard;

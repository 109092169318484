import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import ProjectCard from "../components/project-card";
import Grid from "../components/grid";
import PageHeading from "../components/page-heading";

const Projects = ({ data }) => {
	const projects = data.allContentfulProject.nodes;
	return (
		<Layout>
			<div>
				<PageHeading text={"Projects"} />
				<Grid>
					{projects.map((project) => (
						<ProjectCard project={project} key={project.slug} />
					))}
				</Grid>
			</div>
		</Layout>
	);
};

export default Projects;

export const pageQuery = graphql`
	query ProjectsQuery {
		allContentfulProject(sort: { fields: [date], order: DESC }) {
			nodes {
				title
				date(formatString: "YYYY")
				id
				slug
				thumbnail {
					gatsbyImageData(
						aspectRatio: 1.5
						placeholder: DOMINANT_COLOR
						quality: 85
					)
				}
			}
		}
	}
`;
